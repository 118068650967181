export const degreeInterestOptions: any = [
    {
        "type": "Career Training",
        "value": "1"
    },
    {
        "type": "Associates Degrees",
        "value": "2"
    },
    {
        "type": "Bachelor's Degrees",
        "value": "3"
    },
    {
        "type": "Master's Degrees",
        "value": "4"
    }
]


export const mPrograms: any =
    [{ "text": "Medical Assisting", "value": "500" },
    { "text": "Certified Nursing Assistant (CNA)", "value": "501" },
    { "text": "Allied Health", "value": "502" },
    { "text": "Emergency Management (EMT)", "value": "503" },
    { "text": "Health Science", "value": "505" },
    { "text": "Healthcare Administration", "value": "506" },
    { "text": "Healthcare Management", "value": "507" },
    { "text": "Dental Or Pharmacy Technician", "value": "508" },
    { "text": "Medical Billing & Coding", "value": "509" },
    { "text": "Medical Office Assistant", "value": "510" },
    { "text": "Nutition,Fitness,and Sports Science", "value": "511" },
    { "text": "Public Health", "value": "512" },
    ]


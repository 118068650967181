import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { TDegreeInterest, THealthCareMProgram } from '../../types/common';
import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { WebContext } from '../../contextProvider/webContextProvider';


interface IMenuDropDownProps {
  degreeInterestDDMenu: TDegreeInterest[];
  degreeInterestValue: string;
  degreeInterestHandler: (event: SelectChangeEvent) => void;
  mProgramDDMenu: THealthCareMProgram[];
  mProgramDDValue: string;
  mProgramDDHandler: (event: SelectChangeEvent) => void;
  handleSubmit: (event: any) => void;
  areaStudyDDMenu?: any[];
  areaStudyDDValue?: string;
  areaStudyDDHandler?: (event: SelectChangeEvent) => void;
}

const MenuDropDownComponent: React.FC<IMenuDropDownProps> = ({
  degreeInterestDDMenu,
  degreeInterestValue,
  degreeInterestHandler,
  mProgramDDMenu,
  mProgramDDValue,
  mProgramDDHandler,
  handleSubmit,
  areaStudyDDMenu,
  areaStudyDDValue,
  areaStudyDDHandler,
}) => {
  const { webContent, setWebContent } = useContext(WebContext);
  const pathName = useLocation();
  const currentPath = pathName.pathname;
  const [isValid, setIsValid] = useState({
    degreeInterest: false,
    mProgram: false,
    areaStudy: false,
  });
  const handleError = (e: any) => {
   // const conditions = [
    const conditions: any = [
      //   { value: degreeInterestValue, stateKey: 'degreeInterest' },
      //   { value: mProgramDDValue, stateKey: 'mProgram' },
      ];

    webContent?.defaultData?.template == 1
    ? conditions.push(
        { value: areaStudyDDValue, stateKey: 'areaStudy' },
        { value: mProgramDDValue, stateKey: 'mProgram' }
      )
    : conditions.push({ value: mProgramDDValue, stateKey: 'mProgram' });
  const hasError = conditions.some((condition: any) => {
    // const hasError = conditions.some(condition => {
      if (!condition.value) {
        setIsValid(prevState => ({
          ...prevState,
          [condition.stateKey]: true,
        }));
        return true;
      }
      return false;
    });
    if (!hasError) {
      handleSubmit(e);
    }
  };
  useEffect(() => {
    // console.log(isValid)
  }, [isValid]);

  return (
    <Card sx={{ mb: '30px' }} className="mobile-widget">
      <CardContent sx={{ p: '16px !important' }}>
        <Box mb={2}>
          <FormControl variant="filled" fullWidth className={`custom-select ${
              isValid.degreeInterest ? 'custom-error' : ''
            }`}>
            <InputLabel>{degreeInterestValue ? 'I want my' : 'Select Degree Level'}</InputLabel>
            <Select
              id="degreeInterest"
              value={degreeInterestValue}
              onChange={(e: any) => {
                setIsValid(prevState => ({
                  ...prevState,
                  degreeInterest: false,
                }));
                degreeInterestHandler(e);
              }}
            >
              {degreeInterestDDMenu.map(dDI => {
                return (
                  <MenuItem value={dDI.value} key={dDI.value}>
                    {dDI.type}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        {/* {(degreeInterestValue) && (   
        <Box mb={2}> */}
          {/* <FormControl variant="filled" fullWidth className={`custom-select ${
                isValid.mProgram ? 'custom-error' : ''
              }`}>
            <InputLabel>{mProgramDDValue ? 'FOCUSING ON' : 'Select Program'}</InputLabel> */}
            {/* {(degreeInterestValue ) && (  */}
        {webContent?.defaultData?.template == 1 && degreeInterestValue && (
        <Box mb={2}>
          <FormControl
              variant="filled"
              fullWidth
              className={`custom-select ${
                isValid.areaStudy ? 'custom-error' : ''
              }`}
            >
              <InputLabel>
                {areaStudyDDValue ? 'IN' : 'Select Area of Study'}
              </InputLabel>
              <Select
                id="areaStudy"
                value={areaStudyDDValue}
                onChange={e => {
                  setIsValid(prevState => ({
                    ...prevState,
                    areaStudy: false,
                  }));
                  areaStudyDDHandler && areaStudyDDHandler(e);
                }}
              >
                {areaStudyDDMenu?.map(aAI => {
                  if (aAI?.status) {
                    return (
                      <MenuItem value={aAI.category_id} key={aAI.category_id}>
                        {aAI.category_name}
                      </MenuItem>
                    );
                  }
                })}
              </Select>
            </FormControl>
          </Box>
        )}
        {((webContent?.defaultData?.template == 2 && degreeInterestValue) ||
          (webContent?.defaultData?.template == 1 &&
            degreeInterestValue &&
            areaStudyDDValue)) && (
          <Box mb={2}>
            <FormControl
              variant="filled"
              fullWidth
              className={`custom-select ${
                isValid.mProgram ? 'custom-error' : ''
              }`}
            >
            <InputLabel>
                {mProgramDDValue ? 'FOCUSING ON' : 'Select Program'}
              </InputLabel>
            <Select
              id="mProgram"
              value={mProgramDDValue}
              onChange={(e: any) => {
                setIsValid(prevState => ({
                  ...prevState,
                  mProgram: false,
                }));
                mProgramDDHandler(e);
              }}
            >
              {mProgramDDMenu.map(mPI => {
                // return (
                //   <MenuItem value={mPI.value} key={mPI.value}>
                //     {mPI.text}
                //   </MenuItem>
                // );
                if (mPI?.status) {
                  return (
                    <MenuItem value={mPI.value} key={mPI.value}>
                      {mPI.text}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </Box>)}
        <Button
          variant="contained"
          fullWidth
          size="large"
          sx={{
            textTransform: 'none',
            fontSize: '18px',
            borderRadius: '8px',
            minWidth: '150px',
            // backgroundColor: '#015D67',
            backgroundColor: webContent?.defaultData?.secondaryColor,
            '&:hover': {
              // backgroundColor: '#2e757c',
              backgroundColor: webContent?.defaultData?.secondaryColor,
            },
          }}
          onClick={handleError}
        >
          {/* Compare School Options */}
          {webContent?.defaultData?.widgetButton}
        </Button>
      </CardContent>
    </Card>
  );
};

export default MenuDropDownComponent;

import FormIndex from "../components/LeadFormSteps/FormIndex";
import HealthCareListing from "../Container";
export const routePath = [
    {
        name: "Home",
        path: "/",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Medical Assisting",
        path: "/healthcare/medicalassisting",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Certified Nursing Assistant (CNA)",
        path: "/healthcare/certifiednursingassistant(cna)",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Allied Health",
        path: "/healthcare/alliedhealth",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Emergency Management (EMT)",
        path: "/healthcare/emergencymanagement(emt)",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Health Science",
        path: "/healthcare/healthscience",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Healthcare Administration",
        path: "/healthcare/healthcareadministration",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Healthcare Management",
        path: "/healthcare/healthcaremanagement",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Dental or Pharmacy Technician",
        path: "/healthcare/dentalorpharmacytechnician",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Medical Billing & Coding",
        path: "/healthcare/medicalbilling&coding",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Medical Office Assistant",
        path: "/healthcare/medicalofficeassistant",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Nutition, Fitness, and Sports Science",
        path: "/healthcare/nutition,fitness,andsportsscience",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "Public Health",
        path: "/healthcare/publichealth",
        exact: true,
        component: HealthCareListing
    },
    {
        name: "LeadForm",
        path: "/get-information",
        exact: true,
        component: FormIndex
    },
    {
        name: "Page Not Found",
        path: "*",
        exact: true,
        component: HealthCareListing
    }
]
